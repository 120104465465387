import { useSigner } from "../contexts/signer";

function Login() {
  const { login, connected, signerAddress } = useSigner();

  return !connected || !signerAddress ? (
    <button onClick={login}>Connect Wallet</button>
  ) : (
    <button>
      {`${signerAddress?.substring(0, 6)}...${signerAddress?.substring(36)}`}
    </button>
  );
}

export default Login;
