const maticVars = {
  realm: "0x1D0360BaC7299C86Ec8E99d0c1C9A95FEfaF2a11",
  installation: "0x19f870bD94A34b3adAa9CaA439d333DA18d6812A",
  tile: "0x9216c31d8146bCB3eA5a9162Dc1702e8AEDCa355",
  fud: "0x403E967b044d4Be25170310157cB1A4Bf10bdD0f",
  fomo: "0x44A6e0BE76e1D9620A7F76588e4509fE4fa8E8C8",
  alpha: "0x6a3E7C3c6EF65Ee26975b12293cA1AAD7e1dAeD2",
  kek: "0x42E5E06EF5b90Fe15F853F59299Fc96259209c5C",
};

const mumbaiVars = {
  realm: "0x9351e6705590756BAc83f591aDE9f61De5998a84",
  installation: "0x6F8cFe6757F716039498dE53696b1aB5C66Ab428",
  tile: "0xf65848AF98015463F256877b6A4FaD03e71f6cD1",
  fud: "0x8B55bee31a3eA3179873a9D9DA4b8022BEB70e80",
  fomo: "0x51FB5995d5d1A037f7Cbcc05071BDf5B67f2cFCf",
  alpha: "0xe4978101d2D4Bbe6392aDd7B5183087e8c4c037e",
  kek: "0xEF26948bea74912390295C567F6cfd62310eA7fD",
};

export const vars = maticVars;
