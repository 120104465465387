import { ethers } from "ethers";
import { useState, useEffect } from "react";

function Parcel({ id, grid, width, height, realmDiamond }) {
  console.log(grid);

  return (
    <>
      <h3>PARCEL ID: {ethers.utils.formatUnits(id, 0)}</h3>
      <p>
        Size: {width}x{height}
      </p>
      {/*    <input
        onChange={(event) => setX(event.target.value)}
        value={x}
        type="number"
        placeholder="x"
      ></input> */}
      {/*    <input
        onChange={(event) => setY(event.target.value)}
        value={y}
        type="number"
        placeholder="y"
      ></input> */}
      {/*  <input
        onChange={(event) => setEquipId(event.target.value)}
        value={equipId}
        type="number"
        placeholder="installationId"
      ></input> */}
      {/*  {
        <button onClick={() => equipInstallation(id, equipId, x, y)}>
          Equip Installation
        </button>
      }
      {
        <button onClick={() => unequipInstallation(id, equipId, x, y)}>
          Unequip Installation
        </button>
      } */}
      {/*  {checkAlchemica() && (
        <button style={{ display: "block" }} onClick={() => surveyParcel(id)}>
          Survey
        </button>
      )} */}
      {/* {alchemica.length > 0 && (
        <p>
          FUD: {Number(ethers.utils.formatUnits(alchemica[0])).toFixed(2)} FOMO:{" "}
          {Number(ethers.utils.formatUnits(alchemica[1])).toFixed(2)} ALPHA:{" "}
          {Number(ethers.utils.formatUnits(alchemica[2])).toFixed(2)} KEK:{" "}
          {Number(ethers.utils.formatUnits(alchemica[3])).toFixed(2)}
        </p>
      )} */}

      <div
        className="grid"
        style={{ width: `${height * 15}px`, height: `${width * 15}px` }}
      >
        {grid.map((x) =>
          x.map((y) => (
            <div
              style={{
                background: y > 0 ? "red" : "white",
                color: y > 0 ? "white" : "black",
              }}
              className="block"
            >
              {ethers.utils.formatUnits(y, 0)}
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default Parcel;
