import "./App.css";
import { useState, useEffect } from "react";
import realmDiamondABI from "./utils/diamond_realm.json";
import installationDiamondABI from "./utils/diamond_installation.json";
import erc20ABI from "./utils/IERC20.json";
import Parcel from "./components/Parcel";
import MintParcel from "./components/MintParcel";
import Login from "./components/Login";
import { ethers } from "ethers";
import { useSigner } from "./contexts/signer";
import { vars } from "./constants";

function App() {
  const { signer, provider, signerAddress, connected } = useSigner();

  const [realmDiamond, setRealmDiamond] = useState(null);
  const [installationDiamond, setInstallationDiamond] = useState(null);
  const [fudContract, setFudContract] = useState(null);
  const [fomoContract, setFomoContract] = useState(null);
  const [alphaContract, setAlphaContract] = useState(null);
  const [kekContract, setKekContract] = useState(null);
  const [parcels, setParcels] = useState([]);
  const [craftId, setCraftId] = useState("");
  const [alchemicaBalances, setAlchemicaBalances] = useState([]);

  const [inputAddress, setInputAddress] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");

  // const getInstallations = async (id, ids) => {
  //   const list = await installationDiamond.installationBalancesOfTokenByIds(
  //     REALM_DIAMOND_MUMBAI,
  //     id,
  //     ids
  //   );
  //   return list;
  // };

  const getAlchemicaBalances = async () => {
    const fudBalance = await fudContract.balanceOf(signerAddress);
    const fomoBalance = await fomoContract.balanceOf(signerAddress);
    const alphaBalance = await alphaContract.balanceOf(signerAddress);
    const kekBalance = await kekContract.balanceOf(signerAddress);
    setAlchemicaBalances([
      ...[fudBalance, fomoBalance, alphaBalance, kekBalance],
    ]);
  };

  const getParcels = async (address: string) => {
    if (!ethers.utils.getAddress(address)) {
      console.log("invalid");
      alert("Not a valid address!");
      return;
    }

    setCurrentAddress(address);

    const ids = await realmDiamond.tokenIdsOfOwner(address);
    let arr: any = [];
    for (let i = 0; i < ids.length; i++) {
      const info = await realmDiamond.getParcelInfo(ids[i]);
      const size = Number(ethers.utils.formatUnits(info.size, 0));
      let data: any = {};
      switch (size) {
        case 0: {
          data.grid = await realmDiamond.getHumbleGrid(ids[i], 0);
          data.width = 8;
          data.height = 8;
          break;
        }
        case 1: {
          data.grid = await realmDiamond.getReasonableGrid(ids[i], 0);
          data.width = 16;
          data.height = 16;
          break;
        }
        case 2: {
          data.grid = await realmDiamond.getSpaciousVerticalGrid(ids[i], 0);
          data.width = 32;
          data.height = 64;
          break;
        }
        case 3: {
          data.grid = await realmDiamond.getSpaciousHorizontalGrid(ids[i], 0);
          data.width = 64;
          data.height = 32;
          break;
        }
        case 4: {
          data.grid = await realmDiamond.getPaartnerGrid(ids[i], 0);
          data.width = 64;
          data.height = 64;
          break;
        }
      }
      const parcel = (
        <Parcel
          id={ids[i]}
          grid={data.grid}
          key={ids[i]}
          width={data.width}
          height={data.height}
          realmDiamond={realmDiamond}
        />
      );
      arr.push(parcel);
      setParcels([...arr]);
    }
  };

  useEffect(() => {
    if (signer) {
      setRealmDiamond(new ethers.Contract(vars.realm, realmDiamondABI, signer));
      setInstallationDiamond(
        new ethers.Contract(vars.installation, installationDiamondABI, signer)
      );
      setFudContract(new ethers.Contract(vars.fud, erc20ABI, signer));
      setFomoContract(new ethers.Contract(vars.fomo, erc20ABI, signer));
      setAlphaContract(new ethers.Contract(vars.alpha, erc20ABI, signer));
      setKekContract(new ethers.Contract(vars.kek, erc20ABI, signer));
    }
  }, [signer]);

  useEffect(() => {
    if (signerAddress) {
      getParcels(signerAddress);
      getAlchemicaBalances();
    }
  }, [signerAddress]);
  return (
    <div className="App">
      <Login />

      {signerAddress && (
        <div>
          <input
            placeholder="Input address"
            onChange={(input) => setInputAddress(input.currentTarget.value)}
            value={inputAddress}
          ></input>

          <button onClick={() => getParcels(inputAddress)}>Update</button>
        </div>
      )}

      {alchemicaBalances.length > 0 && (
        <>
          <h3>Alchemica Balance:</h3>
          <p>
            FUD:{" "}
            {Number(ethers.utils.formatUnits(alchemicaBalances[0])).toFixed(2)}{" "}
            FOMO:{" "}
            {Number(ethers.utils.formatUnits(alchemicaBalances[1])).toFixed(2)}{" "}
            ALPHA:{" "}
            {Number(ethers.utils.formatUnits(alchemicaBalances[2])).toFixed(2)}{" "}
            KEK:{" "}
            {Number(ethers.utils.formatUnits(alchemicaBalances[3])).toFixed(2)}{" "}
          </p>
        </>
      )}

      {/*   <button style={{ display: "block" }} onClick={() => faucetAlchemica()}>
        Alchemica Faucet
      </button> */}
      {/*   <button style={{ display: "block" }} onClick={() => approveAlchemica()}>
        Alchemica Approve Installation
      </button> */}
      {/* <button
        style={{ display: "block" }}
        onClick={() => approveAlchemicaTile()}
      >
        Alchemica Approve Tile
      </button> */}
      {/*  <MintParcel mintParcel={mintParcel} /> */}
      {/*  <input
        onChange={(event) => setCraftId(event.target.value)}
        value={craftId}
      ></input> */}
      {/*   <label>Look recipe book for IDS</label> */}
      {/*     <button onClick={() => craftInstallations(craftId.split(","))}>
        Craft Installation
      </button> */}

      {signerAddress && (
        <div>
          <p>Parcels owned by {currentAddress}:</p>
          {parcels.length > 0 && parcels.map((parcel) => parcel)}
        </div>
      )}
    </div>
  );
}

export default App;
